<template>
  <div class="i-container">
    <div class="contentBgi"></div>
    <div
      class="i-main i-scrollbar"
      v-loading="loading"
      style="margin-right: 10px;"
    >
      <el-row style="width:1200px;margin:0 auto;">
        <el-col :span="24">
          <div class="topInfo">
            <p class="user">尊敬的用户：</p>
            <p>您好！感谢您给我们提出宝贵的建议。我们会进行严格保密。您的个人信息绝不会向外公开，请根据您的实际情况或内心真实想法如实填写。
            如需查询建议回复情况，您可以注册成为河南政务服务网用户，然后登录德阳市惠企政策通，在用户中心进行查看。</p>
          </div>
        </el-col>
      </el-row>
      <fieldset class="b-fieldset" style="background-color: #fff">
        <legend class="b-fieldset-legend">诉求提出信息</legend>
        <el-form
          ref="form"
          :model="baseinfo"
          label-width="140px"
          style="width: 100%; padding-right: 1.5rem"
        >
          <el-row>
             <el-col :span="22">
              <el-form-item label="诉求类型:" class="item">
                <el-input v-model="sqProblemType" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="企业名称:" class="item">
                <el-input v-model="baseinfo.qymc" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="统一社会信用代码">
                <el-input v-model="baseinfo.shxydm" :disabled="disabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="诉求提出人">
                <el-input v-model="baseinfo.ggmc" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="提出人职务">
                <el-input v-model="baseinfo.ggzw" :disabled="disabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="挂点领导">
                <el-input v-model="baseinfo.leadername" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="诉求提出时间">
                <el-input v-model="baseinfo.createtime" :disabled="disabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="企业联络员">
                <el-input v-model="baseinfo.nnrmc" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="联络员电话">
                <el-input v-model="baseinfo.nnrphone" :disabled="disabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="所属行业">
                <el-input v-model="baseinfo.hymc" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="所属街道">
                <el-input v-model="baseinfo.jdmc" :disabled="disabled" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </fieldset>
      <fieldset class="b-fieldset" style="background-color: #fff">
        <legend class="b-fieldset-legend">诉求工单基本信息</legend>
        <el-form
          ref="form"
          :model="sxform"
          label-width="140px"
          style="width: 100%; padding-right: 1.5rem"
        >
          <el-row>
            <el-col :span="22">
              <el-form-item label="诉求内容" prop="content">
                <el-input type="textarea" v-model="content"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

<!--          <el-row>
            <el-col :span="11">
              <el-form-item label="分类" prop="itempCode">
                <el-select
                  style="width: 100%"
                  v-model="sxform.itempCode"
                  placeholder="请选择分类"
                  @change="itempCodeChange"
                  clearable
                >
                  <el-option
                    v-for="dict in pItemOptions"
                    :key="dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="主题" prop="itemBaseCode">
                <el-select
                  style="width: 100%"
                  v-model="sxform.itemBaseCode"
                  @change="itempBaseCodeChange"
                  placeholder="请选择主题"
                  clearable
                >
                  <el-option
                    v-for="dict in baseItemOptions"
                    :key="dict.id"
                    :label="dict.name"
                    :value="dict.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="11">
              <el-form-item label="事项主项" prop="parentcode">
                <el-select
                  style="width: 100%"
                  v-model="sxform.parentcode"
                  placeholder="请选择事项主项"
                  @change="itemParentChange"
                  clearable
                >
                  <el-option
                    v-for="dict in itemParentList"
                    :key="dict.id"
                    :label="dict.itemname"
                    :value="dict.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="事项子项" prop="name">
                <el-select
                  style="width: 100%"
                  v-model="sxform.name"
                  placeholder="请选择事项子项"
                  @change="selectSxchange"
                  clearable
                  filterable
                  @visible-change="$forceUpdate()"
                >
                  <el-option
                    v-for="dict in sxOptions"
                    :key="dict.id"
                    :label="dict.name"
                    :value="dict.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="事项编码" prop="itemCode">
                <el-input
                  v-model="sxform.itemCode"
                  disabled="disabled"
                  placeholder="请输入事项编码"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="主办单位" prop="deptCode">
                <el-select
                  v-model="deptCodeStr"
                  @change="initDeptCode()"
                  :disabled="deptdisable"
                >
                  <el-option
                    v-for="item in deptoptions"
                    :key="item.deptid"
                    :label="item.deptname"
                    :value="item.deptid"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="协办单位" prop="joindeptids">
                <el-select v-model="sxform.joindeptids">
                  &lt;!&ndash; :disabled="lbdeptdisable" &ndash;&gt;
                  <el-option
                    v-for="item in lbdeptoptions"
                    :key="item.deptid"
                    :label="item.deptname"
                    :value="item.deptid"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="处理时限" prop="limitDays">
                <el-input v-model="sxform.limitDays" disabled="disabled" />
              </el-form-item>
            </el-col>
          </el-row>-->
        </el-form>
      </fieldset>
      <!-- <fieldset class="b-fieldset">
        <legend class="b-fieldset-legend">诉求具体要求信息</legend>
        <parse-form-view-div
          ref="dataform"
          :visible.sync="formViewVisible"
          :form-content="configFormContent"
          :form-data="configFormData"
          :readonly="formReadonly"
          title="表单预览"
          size="100%"
          v-if="fomViewgKey > 1"
        />
      </fieldset> -->
      <div style="text-align: center; margin-top: 2rem; margin-bottom: 2rem">
        <button class="cancelBtn">取消</button>
        <button class="submitBtn" @click="submitData">提交</button>
      </div>
    </div>
    <!-- <common-dialog ref="commonDialog" @close="doSubmitsq" /> -->
  </div>
</template>
<script>
// import {queryqybase} from '@/api/msg/msglist'
// import {listBaseitem} from "@/api/cfginfo/baseitem";
// import {listTypes} from "@/api/cfginfo/type";
// import {sqreport, sqzancun} from "@/api/business/ysl/data";
// import {listParent} from "@/api/cfginfo/itemparent";
// import {queryDept} from '@/api/cfginfo/deptQuery'
// import {getBasicinfomanage, getSxform, listBasicinfomanagesq} from "@/api/cfginfo/permititemconfig";
// import ParseFormViewDiv from './formconfig/ParseFormViewDiv'
// import CommonDialog from "@/components/CommonDialog";
import { mapGetters } from "vuex";
export default {
  name: "sqgdInfo",
  // props: {
  //   userid: {
  //     required: true,
  //     default: "",
  //   },
  //   touserid: {
  //     required: true,
  //     default: "",
  //   },
  //   type: {
  //     required: true,
  //     default: "",
  //   },
  //   handleMsgAr: {
  //     required: false,
  //     default: "",
  //   },
  //   psid: {
  //     required: false,
  //     default: "",
  //   },
  // },
  components: {
    // ParseFormViewDiv,
    // CommonDialog,
  },
  created(){
    this.sqProblemType = sessionStorage.getItem("sqProblemType")
  },
  data() {
    return {
      // 表单参数
      form: {},
      formmsg: {},
      title: "填写意见",
      sxform: {},
      disabled: true,
      showType: 0,
      //表单信息（表单id）
      configFormId: "",
      //表单信息（表单配置数据）
      configFormContent: {},
      //表单信息（表单填写数据）
      configFormData: {},
      //表单可访问属性
      formViewVisible: false,
      //表单key
      fomViewgKey: 1,
      //配置表单制度属性
      formReadonly: false,
      subdata: {},
      sqProblemType:"",//诉求类型
      baseinfo: {},
      //服务大类
      pItemOptions: [],
      //服务小类
      baseItemOptions: [],
      sxOptions: [],
      formitem: {},
      itemParentList: [],
      queryParams: {
        itemtype: "3",
        id: null,
        name: null,
        itemBaseCode: null,
      },
      selectedsx: {
        sxid: "",
        sxmc: "",
      },
      selecteddept: {
        deptid: "",
        deptname: "",
      },
      message: "",
      loading: false,
      deptdisable: false,
      lbdeptdisable: false,
      deptoptions: [],
      lbdeptoptions: [],
      content: "", //诉求内容
      deptCodeStr: "",
      joindeptids: "",
    };
  },
  mounted() {
    this.getSqBasicList();
    this.getsortList();
    // this.getThemeList();
    // this.getitemParentList();
    // this.getsxOptionsList();
    // this.getDeptoptionsList();
    // this.getLbdeptoptionsList();
    // this.main();
    // this.getFormOptions();
    // let cparam = { bsnum: this.bsnum, formtype: "0" };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      token: "getToken",
    }),
  },
  methods: {
    initDeptCode() {
      console.log("-----", this.deptCodeStr);
      console.log("-----", this.sxform.deptcode);
      this.sxform.deptcode = this.deptCodeStr;
      console.log("-----", this.deptoptions);
    },
    // main() {
    //   queryqybase({ userid: this.userid, touserid: this.touserid }).then(
    //     (response) => {
    //       if (response.data) {
    //         let data = response.data;
    //         if (data && data.length > 0) {
    //           this.baseinfo = data[0];
    //         }
    //       }
    //     }
    //   );
    // },
    reset() {
      this.configFormId = "";
      this.configFormContent = {};
      this.formViewVisible = false;
      this.fomViewgKey = 1;
    },
    // getsxList() {
    //   listBasicinfomanagesq(this.queryParams).then((response) => {
    //     if (response.rows) {
    //       this.sxOptions = response.rows;
    //     }
    //   });
    // },

    // close() {
    //   this.$refs.soltDialog.visible = false;
    // },
    // /** 查询表单列表 */
    // getFormOptions() {
    //   this.getpItemOptions();
    //   // this.getBaseitemOptions()
    // },
    // getparentitemList() {
    //   listParent({ ztcode: this.sxform.itemBaseCode }).then((response) => {
    //     this.itemParentList = response.rows;
    //   });
    // },

    // 事项主项发生变化
    itemParentChange(val) {
      this.queryParams.parentcode = val;
      this.sxform.name = "";
      this.reset();
      this.getsxOptionsList();
    },
    selectSxchange(val) {
      this.sxform.deptcode = "";
      this.selectedsx = {};
      let formid = val;
      this.selectedsx.sxid = val;
      this.selectedsx.sxmc = val
        ? this.sxOptions.find((ele) => ele.id === val).name
        : "";
      this.getDeptoptionsList(formid);
      // await getBasicinfomanage(val).then(async (response) => {
      //   let data = response.data;
      //   let formids = data.formid;
      //   if (formids) {
      //     this.getsxCustomerForm({ id: formids, type: "2" });
      //   }
      //   this.sxform = data;
      //   let deptCode = this.sxform.deptCode;
      //   let joindeptids = this.sxform.joindeptids;
      //   if (deptCode) {
      //     if (deptCode != null && deptCode.indexOf(";") > -1) {
      //       await queryDept({ deptcode: deptCode }).then((response) => {
      //         this.deptoptions = response.data;
      //       });
      //     } else if (deptCode != null && deptCode.indexOf(",") > -1) {
      //       await queryDept({ deptcode: deptCode }).then((response) => {
      //         this.deptoptions = response.data;
      //       });
      //     } else {
      //       await queryDept({ deptcode: deptCode }).then((response) => {
      //         this.deptoptions = response.data;
      //       });
      //     }
      //   }
      // if (joindeptids) {
      //   if (joindeptids != null && joindeptids.indexOf(";") > -1) {
      //     await queryDept({ deptcode: joindeptids }).then((response) => {
      //       this.lbdeptoptions = response.data;
      //     });
      //   } else if (joindeptids != null && joindeptids.indexOf(",") > -1) {
      //     await queryDept({ deptcode: joindeptids }).then((response) => {
      //       this.lbdeptoptions = response.data;
      //     });
      //   } else {
      //     await queryDept({ deptcode: joindeptids }).then((response) => {
      //       this.lbdeptoptions = response.data;
      //     });
      //   }
      //   if (this.lbdeptoptions.length == 0) {
      //     this.sxform.joindeptids = "";
      //     this.lbdeptdisable = true;
      //   }
      // } else {
      //   this.lbdeptdisable = true;
      // }
      //   this.sxform.deptCode = this.deptoptions[0].deptid;
      //   this.deptCodeStr = this.sxform.deptCode;
      // });
    },

    //分类选择变化
    itempCodeChange(val) {
      delete this.formitem.itemBaseCode; //每次选择清空上一次的小项选择
      delete this.queryParams.itemBaseCode;
      this.formitem.itempCode = val;
      this.formitem.itempName = val
        ? this.pItemOptions.find((ele) => ele.id === val).typeName
        : "";
      this.queryParams.itempCode = val;
      this.sxform.name = "";
      this.reset();
      // this.getBaseitemOptions();
      this.getThemeList();
    },
    // 主题选择变化
    itempBaseCodeChange(val) {
      this.formitem.itemBaseName = val
        ? this.baseItemOptions.find((ele) => ele.id === val).name
        : "";
      this.queryParams.itemBaseCode = val;
      this.sxform.name = "";
      this.reset();
      this.getitemParentList();
    },
    // itembaseCodeChange(val) {
    //   this.form.itemBaseCode = val;
    // },
    // /** 查询基础事项列表 */
    // getBaseitemOptions() {
    //   let group = {};
    //   if (this.formitem.itempCode) {
    //     group.groupid = this.formitem.itempCode;
    //   } else {
    //     group.groupid = null;
    //   }
    //   listBaseitem(group).then((response) => {
    //     this.baseItemOptions = response.rows;
    //   });
    // },
    // getpItemOptions() {
    //   listTypes().then((response) => {
    //     this.pItemOptions = response.rows;
    //   });
    // },
    // getsxCustomerForm(data) {
    //   getSxform(data).then((response) => {
    //     let rdata = response.data;
    //     if (rdata && rdata[0]) {
    //       this.configFormId = rdata[0].id;
    //       let type = rdata[0].type;
    //       if (type == "1") {
    //         this.configFormContent = JSON.parse(rdata[0].form_content);
    //       } else {
    //         this.configFormContent = JSON.parse(rdata[0].form_content);
    //       }
    //       this.formViewVisible = false;
    //       this.fomViewgKey++;
    //     }
    //   });
    // },
    // async doOpenSqSubmit() {
    //   let flag = this.validform();
    //   let staflag = await this.$refs.dataform.sumbitForm2();
    //   if (staflag == false) {
    //     this.$message.error("表单校验不通过");
    //     return false;
    //   }
    //   if (flag == true) {
    //     let dialog = {
    //       title: "填写意见",
    //       params: {},
    //       width: "600px",
    //       height: "450px",
    //       // component: () => import('./dealInfo.vue')
    //     };
    //     this.$refs.commonDialog.doOpen(dialog);
    //   }
    // },
    // validform() {
    //   if (!this.selectedsx.sxid) {
    //     this.$message.error("请先选择相应的事项");
    //     return false;
    //   }
    //   if (!this.sxform.deptCode) {
    //     this.$message.error("请确认主办单位是否为空");
    //     return false;
    //   }
    //   if (!this.content) {
    //     this.$message.error("请填写诉求内容");
    //     return false;
    //   }
    //   return true;
    // },
    // doSubmitsq(msg) {
    //   this.$refs.commonDialog.visible = false;
    //   this.message = msg.msg;
    //   if (!this.message) {
    //     this.$message.error("先填写意见");
    //     return false;
    //   }
    //   this.$emit("doloadingOpen");
    //   let baseinfo = {};
    //   baseinfo.factnbje = 0;
    //   baseinfo.nbfje = 0;
    //   baseinfo.sxid = this.selectedsx.sxid;
    //   baseinfo.sxmc = this.selectedsx.sxmc;
    //   baseinfo.type = "3";
    //   this.$refs.dataform.sumbitForm2();
    //   let fdata = this.$refs.dataform.geformdata();
    //   let formInfo = [];
    //   let jsonfrom = {};
    //   jsonfrom.subdata = JSON.stringify(fdata);
    //   jsonfrom.formdata = JSON.stringify(this.configFormContent);
    //   jsonfrom.formid = this.formid;
    //   jsonfrom.formtype = "0";
    //   formInfo.push(jsonfrom);
    //   let operator = {};
    //   operator.apptype = "1";
    //   operator.reporter = {
    //     operateaccount: "username1",
    //     operateid: "5522525",
    //     optaddress: "河南福田区梅林街道xx",
    //     optbirthday: "1999-09-09",
    //     optcertificatename: "二代身份证",
    //     optcertificatenumber: "42111112211111",
    //     optcertificatetype: "2",
    //     optemail: "2525@qq.com",
    //     optgender: "男",
    //     optmobilephone: "14565456554",
    //     optname: "李小龙",
    //   };
    //   operator.enterprise = {};
    //   operator.enterprise.unitcardcode = this.baseinfo.shxydm;
    //   operator.enterprise.unitname = this.baseinfo.qymc;
    //   let sqInfo = {};
    //   sqInfo.userid = this.userid;
    //   sqInfo.sxid = this.selectedsx.sxid;
    //   sqInfo.sxname = this.selectedsx.sxmc;
    //   sqInfo.sqapplyer = this.baseinfo.qymc;
    //   sqInfo.ggmc = this.baseinfo.ggmc;
    //   sqInfo.ggzw = this.baseinfo.ggzw;
    //   sqInfo.leaderid = this.touserid;
    //   sqInfo.leadername = this.baseinfo.leadername;
    //   sqInfo.content = this.content;
    //   sqInfo.message = this.message;
    //   sqInfo.tyshxydm = this.baseinfo.shxydm;
    //   // let dept = this.$refs.deptcas.getSelOptions();
    //   if (this.sxform.deptCode) {
    //     /*let deptids = this.sxform.deptCode.split(';');
    //       let getDeptNames1 = this.getDeptNames(deptids, dept);
    //       sqInfo.deptname = getDeptNames1.join(',');
    //       sqInfo.deptid = deptids.join(',');*/
    //     let getDeptNames1 = this.getDeptName(
    //       this.sxform.deptCode,
    //       this.deptoptions
    //     );
    //     sqInfo.deptname = getDeptNames1;
    //     sqInfo.deptid = this.sxform.deptCode;
    //   }
    //   let getDeptNames1 = this.getDeptName(this.deptCodeStr, this.deptoptions);
    //   sqInfo.deptname = getDeptNames1;
    //   sqInfo.deptid = this.deptCodeStr;
    //   // let depts = this.$refs.joindeptCascader.getSelOptions();
    //   if (this.sxform.joindeptids) {
    //     let deptids = this.sxform.joindeptids.split(";");
    //     let getDeptNames = this.getDeptNamearr(deptids, this.lbdeptoptions);
    //     sqInfo.lbdeptname = getDeptNames.join(",");
    //     sqInfo.lbdeptid = deptids.join(",");
    //   }
    //   sqInfo.sqtypeone = this.formitem.itempName;
    //   sqInfo.sqtypetwo = this.formitem.itemBaseName;
    //   sqInfo.nnrid = this.baseinfo.nnrid;
    //   sqInfo.fqzyid = this.baseinfo.fqzyid;
    //   sqInfo.msgcontent = this.handleMsgAr;
    //   sqInfo.psid = this.psid;
    //   sqInfo.clsx = this.sxform.limitDays;
    //   let submitBean = {
    //     baseinfo: baseinfo,
    //     formInfo: formInfo,
    //     operator: operator,
    //     sqInfo: sqInfo,
    //   };
    //   if (this.type == "2") {
    //     sqreport(submitBean).then((response) => {
    //       if (response && response.code == "200") {
    //         this.$emit("doloadingClose");
    //         this.$emit("doclose", {});
    //       } else {
    //         this.$emit("doloadingClose");
    //         this.$message.error("提交失败");
    //       }
    //     });
    //   } else {
    //     sqzancun(submitBean).then((response) => {
    //       if (response && response.code == "200") {
    //         this.$emit("doloadingClose");
    //         this.$emit("doclose", {});
    //       } else {
    //         this.$emit("doloadingClose");
    //         this.$message.error("提交失败");
    //       }
    //     });
    //   }
    // },
    // getDeptNames(keyList, labelList) {
    //   let names = [];
    //   let _this = this;
    //   let childlist = [];
    //   if (labelList && labelList.length > 0) {
    //     let labelarr = labelList[0];
    //     let children = labelarr.children;
    //     if (children && children.length > 0) {
    //       childlist = children[0].children;
    //     }
    //   }
    //   keyList.forEach((k) => {
    //     let label = _this.getLabel(k, labelList);
    //     names.push(label);
    //   });
    //   return names;
    // },
    // getDeptName(val, arr) {
    //   let rstr = "";
    //   arr.forEach((item) => {
    //     if (val == item.deptid) {
    //       return (rstr = item.deptname);
    //     }
    //   });
    //   return rstr;
    // },
    // getDeptNamearr(keyList, labelList) {
    //   let _this = this;
    //   let names = [];
    //   keyList.forEach((k) => {
    //     let label = _this.getLabel(k, labelList);
    //     names.push(label);
    //   });
    //   return names;
    // },
    // getLabel(val, arr) {
    //   let rstr = "";
    //   arr.forEach((item) => {
    //     if (val == item.value || val == item.key) {
    //       return (rstr = item.label);
    //     } else if (val == item.deptid) {
    //       return (rstr = item.deptname);
    //     }
    //   });
    //   return rstr;
    // },

    //获取基本信息
    getSqBasicList() {
      let params = {
        userid: this.userInfo.userId,
        touserid: "1",
      };
      let that = this;
      let url = "/dev-api/sqzdManager/data/find/sq_qybase_list";
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.baseinfo = r.data[0];
        })
        .catch((e) => {});
    },
    // 获取分类
    getsortList() {
      let url = "/dev-api/business/sq-apply/queryPerm";
      this.$httpApi
        .get(url)
        .then((r) => {
          this.pItemOptions = r.rows;
        })
        .catch((e) => {});
    },
    // 获取主题
    getThemeList() {
      let groupid = "";
      if (this.formitem.itempCode) {
        groupid = this.formitem.itempCode;
      } else {
        groupid = null;
      }
      let params = {
        groupid: groupid,
      };
      let that = this;
      let url = "/dev-api/business/sq-apply/theme";
      this.$httpApi
        .get(url, params)
        .then((r) => {
          that.baseItemOptions = r.rows;
        })
        .catch((e) => {});
    },
    // 获取事项主项
    getitemParentList() {
      let params = {
        ztcode: this.sxform.itemBaseCode,
      };
      let that = this;
      let url = "/dev-api/business/sq-apply/perparentlist";
      this.$httpApi
        .get(url, params)
        .then((r) => {
          that.itemParentList = r.rows;
        })
        .catch((e) => {});
    },
    // 获取事项子项（没有权限）
    getsxOptionsList() {
      console.log(this.queryParams);
      let that = this;
      let url = "/dev-api/business/sq-apply/personlist";
      this.$httpApi
        .get(url, this.queryParams)
        .then((r) => {
          if (r.rows) {
            that.sxOptions = r.rows;
          }
        })
        .catch((e) => {});
    },
    // 获取主办单位（没有权限）
    getDeptoptionsList(id) {
      let that = this;
      let url = `/dev-api/business/sq-apply/${id}`;
      this.$httpApi
        .get(url)
        .then((r) => {
          let resultData = r.data;
          let deptname = resultData.deptName.split(",");
          let deptcode = resultData.deptCode.split(",");
          for (let i = 0; i < deptname.length; i++) {
            let deptpbj = {
              deptname: deptname[i],
              deptid: deptcode[i],
            };
            that.deptoptions.push(deptpbj);
          }
          // deptname.forEach(element,index => {
          //   that.deptoptions[index].deptname=element
          // });
          // deptcode.forEach(element,index => {
          //   that.deptoptions[index].deptid=element
          // });
          that.sxform.itemCode = resultData.itemCode;
          that.sxform.limitDays = resultData.limitDays;
          that.joindeptids = that.sxform.joindeptids;
        })
        .then((res) => {
          if (that.joindeptids) {
            that.getLbdeptoptionsList();
          }
        })
        .catch((e) => {});
    },
    // 获取协办单位
    getLbdeptoptionsList() {
      let params = {
        deptcode: this.joindeptids,
        namespace: "systemConfigQuery",
      };
      let that = this;
      let url = "/dev-api/business/sq-apply/data/find/systemConfigQuery";
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.lbdeptoptions = r.data;
        })
        .catch((e) => {
        });
    },

    //提交表单数据
    submitData() {
      this.loading = true;
      let params = {
        userid: this.userInfo.userId, //用户Id
        tyshxydm: this.baseinfo.shxydm, //统一信用代码
        title: '问题咨询', //诉求主题（不清楚是哪个，暂时替代）
        content: this.content, //诉求内容
        leaderid: this.baseinfo.leaderid, //诉求领导关联id
        leadername: this.baseinfo.leadername, //诉求领导账号名称
        status: "1", //发起诉求默认为1
        sqapplyer: this.baseinfo.qymc, //诉求提出者（公司名称）
        sqtypeone: this.sxform.itempCode, //诉求大类（分类）
        sqtypetwo: this.sxform.itemBaseCode, //诉求小类（主题）
        deptid: this.deptCodeStr, //主管部门id
        clsx: this.sxform.limitDays, //处理时限
        deptname: this.deptCodeStr, //主管部门名称
        lbdeptid: "", //联办部门id
        lbdeptname: this.sxform.joindeptids, //联办单位名称
        sxid: this.sxform.itemCode, //事项id
        sxname: "", //事项名称
        sqProblemType:this.sqProblemType,
        // createtime:this.baseinfo.createtime//诉求提出时间
      };
      let url = "/dev-api/business/sq-apply/applySq";
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((r) => {
          that.loading = false;
          that.$message.success("提交成功");
            that.$router.push({ name: "myAppeal" });
        })
        .catch((e) => {
          that.loading = false;
          that.$message.error("提交失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.b-fieldset {
  width: 1200px;
  border: 1px solid #dfe4ed;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 1rem;
}

.b-fieldset-legend {
  font-weight: 700;
  color: #2194ff;
  padding: 0 5px;
  font-size: 16px;
}
/* .el-tabs {
  padding-left: 1rem;
  margin-top: 1rem;
} */
.el-collapse-item__header {
  font-size: 18px;
  color: #2194ff;
  font-weight: bold;
}
.contentBgi {
  width: 100%;
  height: 11rem;
  background-image: url("~@/assets/images/底图.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.topInfo {
  width: 100%;
  min-height: 90px;
  margin: 10px auto;
  border-bottom: 4px solid #dc1a1a;
  p {
    line-height: 1.8;
  }
  .user {
    color: #000;
    font-weight: 700;

  }
}
.cancelBtn {
  margin-right: 0.625rem;
  background-color: #aaadb7;
  color: #fff;
  width: 7.5rem;
  height: 2rem;
  line-height: 1;
  border: unset;
}
.submitBtn {
  background-color: #ff4700;
  color: #fff;
  width: 7.5rem;
  height: 2rem;
  line-height: 1;
}
</style>

